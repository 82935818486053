<template>
  <dialog-generic v-model="visible" :width="600" :loading="loading">
    <template #title>Personal settings</template>
    <v-select
      v-model="language"
      v-hasAccess="permissions.SYSTEM.READ()"
      v-bind="languageAttrs"
      :items="availableLanguages"
      label="Language"
      item-title="label"
      item-value="item"
      return-object
    />
    <v-select
      v-model="dateFormat"
      v-bind="dateFormatAttrs"
      :items="availableDateFormats"
      label="Date and time format"
      color="primary"
      return-object
    />
    <v-text-field
      v-model="email"
      v-bind="emailAttrs"
      label="Email"
    />
    <v-switch
      v-model="use2FA"
      :disabled="!hasEmail"
      v-bind="use2FAAttrs"
      :false-value="false"
      :true-value="true"
      hint="2FA will work only if you have an email address linked to your account"
      label="Enable two factor authentication"
      color="primary"
      persistent-hint
      @change="update2faSettings"
    />
    <dialog-otp-qr-code v-model="showQrCodeDialog" @cancel="use2FA = false" />
    <template #actions>
      <v-btn text="CANCEL" color="red" plain @click="visible = false" />
      <v-btn text="SAVE" color="green" plain @click="onSubmit" />
    </template>
  </dialog-generic>
</template>

<script lang="ts" setup>
import { toTypedSchema } from '@vee-validate/zod'
import type { PersonalSettings } from '~/types/Account'
import { PersonalSettingsSchema } from '~/types/AccountValidation'

const { $notifications } = useNuxtApp()
const settingsStore = useSettingsStore()
const { settings } = storeToRefs(settingsStore)
const visible = defineModel<boolean>()
const { permissions } = storeToRefs(useSecurityStore())
const availableDateFormats = ref<string[]>(['DD-MM-YYYY HH:mm', 'MM-DD-YYYY HH:mm', 'YYYY-MM-DD HH:mm'])
const triggerLoading = ref(false)
const showQrCodeDialog = ref(false)
const loading = computed(() => loadingAvailableLanguages.value || triggerLoading.value)
const hasEmail = computed(() => email.value != null && email.value.length > 0 && isFieldValid('email'))

const { state: availableLanguages, execute: loadAvailableLanguages, isLoading: loadingAvailableLanguages, error: errorLoadingLanguages } = useAsyncState(() => findAvailableLanguages().then(r => r.data), [], { immediate: false })

const { defineField, handleSubmit, isFieldValid, setValues } = useForm<PersonalSettings>({
  validationSchema: toTypedSchema(PersonalSettingsSchema),
  initialValues: settings.value,
})

const [email, emailAttrs] = defineField('email', VUETIFY_INPUT_CONFIG)
const [language, languageAttrs] = defineField('accountSettings.language', VUETIFY_INPUT_CONFIG)
const [dateFormat, dateFormatAttrs] = defineField('accountSettings.dateFormat', VUETIFY_INPUT_CONFIG)
const [use2FA, use2FAAttrs] = defineField('use2FA')

const onSubmit = handleSubmit((values) => {
  triggerLoading.value = true
  settingsStore.saveAccountSettings(values)
    .then(() => $notifications.success('Personal settings successfully saved'))
    .catch(() => $notifications.error('An error occurred while saving account settings'))
    .finally(() => {
      triggerLoading.value = false
      visible.value = false
    })
})

function update2faSettings() {
  if (use2FA.value) {
    showQrCodeDialog.value = true
  }
}

watch(settings, n => setValues(n))
whenever(visible, () => loadAvailableLanguages())

watchEffect(() => {
  if (errorLoadingLanguages.value) {
    $notifications.error('An error occurred while loading available languages')
  }
})
</script>

<template>
  <v-menu :close-on-content-click="false" open-on-hover>
    <template #activator="{ props }">
      <v-btn plain v-bind="props" class="d-flex align-center">
        <v-icon>{{ mdiAccount }}</v-icon>
        <span class="ml-2">{{ securityStore.username }}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item dense @click="openAccountSettingsDialog">
        <div>
          <v-icon class="mr-2">{{ mdiAccountCog }}</v-icon>
          <span>Personal settings</span>
        </div>
      </v-list-item>
      <v-list-item dense class="checkbox">
        <div>
          <v-checkbox-btn v-model="isDark" label="Dark theme" hide-details @update:model-value="toggle" />
        </div>
      </v-list-item>
      <v-list-item dense @click="logout">
        <div>
          <v-icon class="mr-2">{{ mdiLogout }}</v-icon>
          <span>Logout</span>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
  <dialog-personal-settings v-model="showAccountSettingsDialog" />
</template>

<script lang="ts" setup>
import { mdiAccount, mdiLogout, mdiAccountCog } from '@mdi/js'
import { StorageSerializers } from '@vueuse/core'

const theme = useLocalStorage<string>('theme', 'light', { serializer: StorageSerializers.string })
const isDark = ref(theme.value === 'dark')
const securityStore = useSecurityStore()
const { toggleTheme } = useSettingsStore()
const showAccountSettingsDialog = ref(false)

function toggle() {
  toggleTheme(isDark.value)
}

function logout() {
  securityStore.unload()
  navigateTo('/login')
}

function openAccountSettingsDialog() {
  showAccountSettingsDialog.value = true
}
</script>

<style lang="css">
.checkbox{
  margin-left: -10px;
}
</style>

<template>
  <v-app-bar density="compact">
    <v-app-bar-nav-icon density="comfortable" @click="model = !model" />
    <v-spacer />
    <headbar-account-menu />
  </v-app-bar>
</template>

<script lang="ts" setup>
const model = defineModel<boolean>({ required: true })
</script>

<template>
  <dialog-generic v-model="model" :width="500" :loading="loading" :allow-fullscreen="false" @closed="cancel">
    <template #title>Scan QR Code</template>
    <v-row v-if="step === 1">
      <v-col :cols="12">Please scan the OTP code using an authenticator app. This code it's unique and it will not be available after closing this window.</v-col>
      <v-col :cols="12" class="d-flex justify-center">
        <img height="200" width="200" :src="img">
      </v-col>
      <v-col :cols="12" class="d-flex justify-center">
        <v-btn color="primary" variant="text" @click="step = 2">
          CAN'T SCAN QR CODE?
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="step === 2">
      <v-col :cols="12" class="d-flex justify-center font-weight-bold">Can't scan QR code?</v-col>
      <v-col :cols="12">If you can't scan the QR code with your camera, enter the following code into the authentification app to link it to your Optilink account</v-col>
      <v-col :cols="12">
        <v-text-field :value="secret" readonly>
          <template #append>
            <v-icon v-if="isSupported" :icon="mdiContentCopy" @click="copy(secret)" />
          </template>
        </v-text-field>
      </v-col>
      <v-col :cols="12" class="d-flex justify-center">
        <v-btn color="primary" variant="text" @click="step = 1">
          BACK
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="step === 3">
      <v-col :cols="12" class="d-flex justify-center font-weight-bold">Verify the authentication code now</v-col>
      <v-col :cols="12" class="d-flex justify-center">
        From the authentication app, get a code and enter it below to complete the verification process. If you don't have an authentication app on your device, you'll need to install one now.
      </v-col>
      <v-col :cols="12">
        <v-otp-input length="6" type="number" :disabled="loading" @finish="checkCode" />
      </v-col>
    </v-row>
    <template #actions>
      <div class="w-100 pr-1">
        <v-btn text="Cancel" color="accent" variant="outlined" block @click="cancel" />
      </div>
      <div class="w-100 pl-1">
        <v-btn text="Proceed" color="primary" variant="outlined" block @click="proceed" />
      </div>
    </template>
  </dialog-generic>
</template>

<script lang="ts" setup>
import { mdiContentCopy } from '@mdi/js'
import { useClipboard } from '@vueuse/core'
import { setup2Fa, verify2Fa } from '~/utils/backend/accounts-api'

const model = defineModel<boolean>()
const emit = defineEmits(['done', 'cancel'])
const { $notifications } = useNuxtApp()
const toggleLoading = ref(false)
const step = ref(1)

const setupKeyController = useAsyncState(() => setup2Fa().then(r => r.data), { qrCode: '', secret: '' }, { immediate: false })
const setupKey = setupKeyController.state
const img = computed(() => `data:image/png;base64,${setupKey.value.qrCode}`)
const loading = computed(() => toggleLoading.value || setupKeyController.isLoading.value)
const secret = computed(() => setupKey.value.secret)
const { copy, copied, isSupported } = useClipboard({ source: secret })

function checkCode(code: string) {
  toggleLoading.value = true
  verify2Fa(setupKey.value.secret, code).then(() => {
    emit('done')
    model.value = false
    $notifications.success('Two factor authentication setup complete.')
  }).catch(() => $notifications.error('Two factor authentication code it\'s invalid, did you set it up correctly?'))
    .finally(() => { toggleLoading.value = false })
}

function cancel() {
  model.value = false
  step.value = 1
  emit('cancel')
}

function proceed() {
  if (step.value === 1 || step.value === 2) {
    step.value = 3
  }
}

whenever(copied, () => $notifications.success('Code copied to clipboard'))
whenever(model, () => setupKeyController.execute())
whenever(setupKeyController.error, () => $notifications.error('An error occured, try again later'))
</script>

<template>
  <v-navigation-drawer v-model="model" width="240">
    <template #prepend>
      <v-list-item class="d-flex justify-center px-0 mb-1 logo" :height="48" :elevation="4">
        <img :src="logo">
      </v-list-item>
    </template>
    <v-list nav dense>
      <div v-for="link in links" :key="link.title">
        <v-list-item v-if="!link.children" :to="link.link">
          <template #prepend>
            <v-icon :icon="link.icon" />
          </template>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item>
        <v-list-group v-if="link.children">
          <template #activator="{ props }">
            <v-list-item v-bind="props" :title="link.title">
              <template #prepend>
                <v-badge v-if="link.badge" color="red" :content="newSupportItems" overlap :model-value="newSupportItems > 0">
                  <v-icon>{{ link.icon }}</v-icon>
                </v-badge>
                <v-icon v-else>{{ link.icon }}</v-icon>
              </template>
            </v-list-item>
          </template>
          <v-list-item v-for="cLink in link.children" :key="cLink.title" :to="cLink.link" class="ml-2">
            <template #prepend>
              <v-icon :icon="cLink.icon" />
            </template>
            <v-list-item-title>{{ cLink.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { mdiMonitorDashboard, mdiChip, mdiCloudUploadOutline, mdiFormatListBulletedSquare, mdiAccountGroup, mdiEmailMultiple, mdiShieldAccount,
  mdiNut, mdiAt, mdiSignal, mdiFileExport, mdiFileCode, mdiClipboardPulse, mdiAlphaBCircle, mdiFaceAgent, mdiShieldBugOutline, mdiNewBox,
  mdiSecurity, mdiFormatListText, mdiFormatListCheckbox
} from '@mdi/js'
import { useRouter } from 'vue-router'
import logo from '@/assets/logo-vertical.png'
import type { NavigationLink } from '~/types/Sidebar'
import { countItems } from '~/utils/backend/support-api'

const newSupportItems = ref<number>(0)
const model = defineModel<boolean>({ required: true })
const links = computed(() => navigationLinks.value.filter(l => l.enabled))
const { $notifications } = useNuxtApp()
const { unload } = useSecurityStore()
const { permissions } = storeToRefs(useSecurityStore())
const navigationLinks = ref<NavigationLink[]>([
  {
    title: 'Dashboard',
    icon: mdiMonitorDashboard,
    link: '/dashboard',
    enabled: permissions.value.SYSTEM.READ(),
  },
  {
    title: 'Devices',
    icon: mdiChip,
    link: '/devices',
    enabled: permissions.value.DEVICES.READ(),
  },
  {
    title: 'Software versions',
    icon: mdiCloudUploadOutline,
    link: '/software-versions',
    enabled: permissions.value.DEVICE_UPDATES.READ(),
  },
  {
    title: 'Dealers/Subdealers',
    icon: mdiFormatListBulletedSquare,
    link: '/dealers-and-subdealers',
    enabled: permissions.value.DEALERS.READ() || permissions.value.SUBDEALERS.READ(),
  },
  {
    title: 'Accounts',
    icon: mdiAccountGroup,
    link: '/accounts',
    enabled: permissions.value.ACCOUNTS.READ(),
  },
  {
    title: 'Ftp Users',
    icon: mdiMonitorDashboard,
    link: '/ftp-users',
    enabled: permissions.value.FTP_ACCOUNTS.READ() && process.env.VUE_APP_PROFILE !== 'acceptance',
  },
  {
    title: 'Mail Users',
    icon: mdiEmailMultiple,
    link: '/mail-users',
    enabled: permissions.value.MAIL_ACCOUNTS.READ() && process.env.VUE_APP_PROFILE !== 'acceptance',
  },
  {
    title: 'Access Groups',
    icon: mdiShieldAccount,
    link: '/access-groups',
    enabled: permissions.value.ACCESS_GROUPS.READ(),
  },
  {
    title: 'Subscription plans',
    icon: mdiNut,
    link: '/subscription-plans',
    enabled: permissions.value.SUBSCRIPTION_PLANS.READ(),
  },
  {
    title: 'Domains',
    icon: mdiAt,
    link: '/domains',
    enabled: permissions.value.DOMAINS.READ(),
  },
  {
    title: 'Buffers',
    icon: mdiSignal,
    link: '/buffers',
    enabled: permissions.value.BUFFERS.READ(),
  },
  {
    title: 'Export Templates',
    icon: mdiFileExport,
    link: '/export-templates',
    enabled: permissions.value.EXPORT_TEMPLATES.READ(),
  },
  {
    title: 'ADF Management',
    icon: mdiFileCode,
    link: '/adf-management',
    enabled: permissions.value.ADF_FILES.READ(),
  },
  {
    title: 'Export Monitor',
    icon: mdiClipboardPulse,
    link: '/export-monitor',
    enabled: permissions.value.EXPORT_MONITOR.READ(),
  },
  {
    title: 'Support',
    icon: mdiFaceAgent,
    link: '',
    badge: 'newSupportItems',
    enabled: permissions.value.SUPPORT.READ(),
    children: [
      {
        title: 'Bug reports',
        icon: mdiShieldBugOutline,
        link: '/support/bug-reports',
        enabled: permissions.value.SUPPORT.READ(),
      },
      {
        title: 'Feature requests',
        icon: mdiNewBox,
        link: '/support/feature-requests',
        enabled: permissions.value.SUPPORT.READ(),
      },
    ],
  },
  {
    title: 'Security',
    icon: mdiSecurity,
    link: '',
    enabled: permissions.value.SECURITY.READ(),
    children: [
      {
        title: 'Roles',
        icon: mdiFormatListText,
        link: '/security/roles',
        enabled: permissions.value.SECURITY.READ(),
      },
      {
        title: 'Policies',
        icon: mdiFormatListCheckbox,
        link: '/security/policies',
        enabled: permissions.value.SECURITY.READ(),
      },
    ],
  },
  {
    title: 'Virtual devices',
    icon: mdiChip,
    link: '/virtual-devices-management',
    enabled: permissions.value.SYSTEM.READ(),
  },
  {
    title: 'Storyblok',
    icon: mdiAlphaBCircle,
    link: '/storyblok',
    enabled: permissions.value.STORYBLOK.READ(),
  },

])

function findFirstAccessible(routes: NavigationLink[]): NavigationLink | null {
  let found = null
  for (const route of routes) {
    if (route.enabled && route.link !== null) {
      return route
    }
    if (route.children) {
      found = findFirstAccessible(route.children)
      if (found !== null) {
        return found
      }
    }
  }
  return null
}

function logout() {
  unload()
}

onMounted(() => {
  if (location.pathname === '/') {
    if (permissions.value.SYSTEM.READ()) {
      useRouter().push({ name: 'Dashboard' })
    } else {
      const route = findFirstAccessible(navigationLinks.value)
      if (route === null) {
        $notifications.error('You don\'t have access to any module')
        logout()
      } else {
        useRouter().push({ path: route.link })
      }
    }
  }

  if (permissions.value.SUPPORT.READ()) {
    countItems()
      .then((r) => { newSupportItems.value = r.data })
      .catch(() => {})
  }
})
</script>

<style lang="css" scoped>
.logo img {
  width: 100%;
  height: 46px;
}
</style>
